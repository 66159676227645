import { actionTypes } from "../actions/actionTypes/globalActionType";

let initialState = {
    isToolBarOpen: false
};

/** 
 * Redux reducers.
 * Used to update state in the store after actions are issued.
 */
export function globalReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.TOGGLETOOLBAR:
            return {
                ...state,
                isToolBarOpen: action.data.state
            };
        default:
            return state;
    }
}
