import React, { useRef } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import IconButton from '@mui/material/IconButton';
import LoginStyles from './style';
import AccountCircle from '@mui/icons-material/AccountCircle';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import HCMLogo from '../../images/peoplehcm.webp';
import ErrorIcon from '@mui/icons-material/Error';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Typography, Box, FormHelperText, Alert, Link } from '@mui/material';
import { loginActions } from '../../actions/loginAction';
import { LoginLoader } from '../utils/LoginLoader';

function Signin({ mobileMatchMediaQuery, setForgotPasswordEnable }) {
  let formRef = useRef();
  const classes = LoginStyles();

  const [showPassword, setShowPassword] = React.useState(false);
  const dispatch = useDispatch();

  const handleClickShowPassword = (event) => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const loginLoader = useSelector((state) => state.loggedIn.loginLoader);

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          Username: '',
          password: '',
        }}
        onSubmit={(values) => {
          localStorage.setItem('pyuseridentifier', values.Username);
          localStorage.setItem('password', values.password);
          localStorage.setItem('isSSO', 'false');
          dispatch(loginActions.loginLoader({}));
          dispatch(loginActions.IsUserLoggedIn(true, values.Username));
        }}
        innerRef={formRef}
        validationSchema={Yup.object().shape({
          Username: Yup.string().required(`Please enter Username`),
          password: Yup.string().required(`Please enter password`),
        })}
        validateOnChange={true}
        validateOnBlur={false}>
        {({ values, setFieldValue, handleReset, handleChange, touched, errors }) => (
          <Form noValidate autoComplete="off">
            <div>
              <div>
                <Grid container className={classes.container}>
                  <Grid item md={12} className={classes.rightContainer}>
                    {!mobileMatchMediaQuery && (
                      <Box m={1}>
                        <Typography
                          variant="h6"
                          align="center"
                          style={{
                            fontWeight: 700,
                            marginBottom: '-16px',
                            color: '#717171',
                          }}>
                          Welcome to
                        </Typography>

                        <img src={HCMLogo} alt="logo" style={{ width: '200px' }} />
                      </Box>
                    )}

                    {window.noServerFound && (
                      <Box mb={2}>
                        <Alert icon={<ErrorIcon color="secondary" />} severity="error">
                          Server Not Found
                        </Alert>
                      </Box>
                    )}
                    <Box className={classes.inputs}>
                      <Box pb={2} pl={2}>
                        <Grid
                          container
                          spacing={2}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            padding: '0px 10px',
                          }}>
                          <Grid item md={12}>
                            <FormControl
                              fullWidth
                              error={errors.Username && touched.Username}
                              variant="standard">
                              <InputLabel htmlFor="input-with-icon-adornment">
                                Username or Email
                              </InputLabel>
                              <Input
                                name="Username"
                                autoFocus
                                color="primary"
                                onChange={handleChange}
                                id="input-with-icon-adornment"
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton disabled>
                                      <AccountCircle color="primary" />
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                              <FormHelperText>
                                {errors.Username && touched.Username ? errors.Username : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item md={12}>
                            <FormControl
                              fullWidth
                              error={errors.password && touched.password}
                              variant="standard">
                              <InputLabel htmlFor="password">Password</InputLabel>
                              <Input
                                id="password"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                onChange={handleChange}
                                value={values.password}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}>
                                      {showPassword ? (
                                        <Visibility color="primary" />
                                      ) : (
                                        <VisibilityOff color="primary" />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                              <FormHelperText>
                                {errors.password && touched.password ? errors.password : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>

                          {!window.noServerFound && (
                            <Grid item md={12}>
                              <Button
                                sx={{ padding: '0px', margin: '0px' }}
                                onClick={() => {
                                  setForgotPasswordEnable(true);
                                }}>
                                Forgot password?
                              </Button>
                            </Grid>
                          )}

                          <Grid item md={12} style={{ textAlign: 'center' }}>
                            <Button
                              size="medium"
                              color="primary"
                              // disabled={!values.Username || !values.password}
                              variant="contained"
                              disabled={loginLoader || window.noServerFound}
                              type="submit">
                              Login
                              {loginLoader && (
                                <React.Fragment>
                                  &nbsp;&nbsp;
                                  <LoginLoader />
                                </React.Fragment>
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default Signin;
