export const endpoints = {
  // Change this URL if you want to point the React application at another Pega server.

  // BASEURL: "http://192.168.94.163:8080/prweb/api/v1",
  // BASEURL2:"http://192.168.94.163:8080/prweb/PRRestService/IndivHCMCases/v1",
  // BASEURL4:"http://192.168.94.163:8080/prweb/PRRestService/RepteesHCMCases/v1",
  // BASEURL3:"http://192.168.94.163:8080/prweb/PRRestService/Currency/v1",

  BASEURL: window.BASEURL,
  BASEURL2: window.BASEURL2,
  BASEURL4: window.BASEURL4,
  BASEURL3: window.BASEURL3,
  ADDFROMZIPURL: window.ADDFROMZIPURL,
  JobsAPI: window.JobsAPI,
  PAYROLL: window.PAYROLL,
  ResetPassword: window.ResetPassword,
  COSMOSAPI: window.COSMOSAPI,
  AUTH: '/authenticate',
  EMPINFO: '/EmpInfo',
  INDIVIDUALEXPENSES: '/individualExpenses',
  COMPLETEINDIVIDUALEXPENSE: '/expensecases',
  GETBASECURRENCY: '/getTotalByBaseCurrency',
  POSTINDIVIDUALEXPENSE: '/individualExpenses',
  GETTEAMEXPENSES: '/getExpensesList',
  GETPERSONALTIMESHEETS: '/CasesAndDetails',
  GETTEAMTIMESHEET: '/RepteesCases',
  DOWNLOADFILE: '/downloadReceipt',
  GETSERVICEREQUESTS: '/SRForUser',
  GETUSERS: '/UsersByString',
  GETTEAMSERVICEREQUESTS: '/SRForManager',
  PROFILE: '/ProfileDetails',
  MBO: '/MBOProcess',
  MBOTeam: '/ReporteesMBOProcess',
  MBOIndividual: '/MBOProcessByUser',
  ONEONONE: '/OneToOneDiscussion',
  MBOINTERACTIONS: '/MBOInteractions',
  getOrganisationsWithDetails: '/OnboardingFieldSource',
  TimeByProject: '/TimeByProject',
  BenefitEnrollment: '/BenefitEnrollment',
  HcmOrgLoc: '/HcmOrgLoc',
  PayRoll: '/PayRoll',
  RECIEPTREADER: '/receiptReader',
  ATS: '/ATS',
  OnboardingEmployee: '/OnboardingEmployee',
  OffBoardingEmployee: '/OffBoardingEmployee',
  DownloadPolicydocument: '/DownloadPolicydocument',
  ViewEncryptedData: '/ViewEncryptedData',
  I9: '/I9',
  teamBenefitEnrollment: '/BenfAdmnActns',
  ViewBeneficiaryEncryptedData: '/ViewBeneficiaryEncryptedData',
  ProjectMaintnance: '/ProjectMaintnance',
  ATSApplicant: '/ATSApplicant',
  Resignation: '/Resignation',
  MDM: '/MDMAdminActions',
  LeaveManagement: '/LeaveManagement',
  Templates: '/ATS/Templates',
  ChangePassword: '/Updatepassword',
  W4Configuration: '/W4Configuration',
  GenerateW2and1099Forms: '/GenerateW2and1099Forms',
  ProjectManagement:'/ProjectManagement',
  MyProjectCases:'/MyProjectCases',
  PayrollDeductions: "/PayrollDeductions",
};
