export const actionTypes = {
  GET_LEAVE_DETAILS_SUCCESS: 'GET_LEAVE_DETAILS_SUCCESS',
  GET_LEAVE_DETAILS_FAILURE: 'GET_LEAVE_DETAILS_FAILURE',
  GET_LEAVE_DETAILS_LOADER: 'GET_LEAVE_DETAILS_LOADER',

  GET_EMP_SUCCESS: 'GET_EMP_SUCCESS',
  GET_EMP_FAILURE: 'GET_EMP_FAILURE',
  GET_EMP_LOADER: 'GET_EMP_LOADER',

  GET_LEAVEMANAGEMENT_SUCCESS: 'GET_LEAVEMANAGEMENT_SUCCESS',
  GET_LEAVEMANAGEMENT_FAILURE: 'GET_LEAVEMANAGEMENT_FAILURE',
  SHOW_LEAVEMANAGEMENT_LOADER_GET: 'SHOW_LEAVEMANAGEMENT_LOADER_GET',

  SUBMIT_LEAVE_MANAGEMENT_SUCCESS: 'SUBMIT_LEAVE_MANAGEMENT_SUCCESS',
  SUBMIT_LEAVE_MANAGEMENT_FAILURE: 'SUBMIT_LEAVE_MANAGEMENT_FAILURE',
  SUBMIT_LEAVE_MANAGEMENT_LOADER: 'SUBMIT_LEAVE_MANAGEMENT_LOADER',

  CLEAR_LM_DATA: 'CLEAR_LM_DATA',
};
