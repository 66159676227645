export const actionTypes = {
  POST_PAYROLL_SUCCESS: 'POST_PAYROLL_SUCCESS',
  POST_PAYROLL_FAILURE: 'POST_PAYROLL_FAILURE',
  SHOW_PAYROLL_LOADER: 'SHOW_PAYROLL_LOADER',
  CHANGE_ORG: 'CHANGE_ORG',

  GET_PAYROLL_SUCCESS: 'GET_PAYROLL_SUCCESS',
  GET_PAYROLL_FAILURE: 'GET_PAYROLL_FAILURE',
  SHOW_PAYROLL_LOADER_GET: 'SHOW_PAYROLL_LOADER_GET',

  GET_W4_CONFIG_DETAILS_SUCCESS: 'GET_W4_CONFIG_DETAILS_SUCCESS',
  GET_W4_CONFIG_DETAILS_FAILURE: 'GET_W4_CONFIG_DETAILS_FAILURE',
  GET_W4_CONFIG_DETAILS_LOADER: 'GET_W4_CONFIG_DETAILS_LOADER',

  POST_W4_CONFIG_DETAILS_SUCCESS: 'POST_W4_CONFIG_DETAILS_SUCCESS',
  POST_W4_CONFIG_DETAILS_FAILURE: 'POST_W4_CONFIG_DETAILS_FAILURE',
  POST_W4_CONFIG_DETAILS_LOADER: 'POST_W4_CONFIG_DETAILS_LOADER',

  RESET_PAYROLL_DATA: 'RESET_PAYROLL_DATA',

  GET_OPEN_CASE_DETAILS_SUCCESS: 'GET_OPEN_CASE_DETAILS_SUCCESS',
  GET_OPEN_CASE_DETAILS_FAILURE: 'GET_OPEN_CASE_DETAILS_FAILURE',

  GET_CALCULATE_TAX_DTLS_SUCCESS: 'GET_CALCULATE_TAX_DTLS_SUCCESS',
  GET_CALCULATE_TAX_DTLS_FAILURE: 'GET_CALCULATE_TAX_DTLS_FAILURE',
  GET_CALCULATE_TAX_DTLS_LOADER: 'GET_CALCULATE_TAX_DTLS_LOADER',

  POST_PROCESS_PAYROLL_SUCCESS: 'POST_PROCESS_PAYROLL_SUCCESS',
  POST_PROCESS_PAYROLL_FAILURE: 'POST_PROCESS_PAYROLL_FAILURE',
  POST_PROCESS_PAYROLL_LOADER: 'POST_PROCESS_PAYROLL_LOADER',

  GET_PAY_STUBS_DATA_SUCCESS: 'GET_PAY_STUBS_DATA_SUCCESS',
  GET_PAY_STUBS_DATA_FAILURE: 'GET_PAY_STUBS_DATA_FAILURE',
  GET_PAY_STUBS_DATA_LOADER: 'GET_PAY_STUBS_DATA_LOADER',

  DOWNLOAD_PAY_STUBS_DATA_SUCCESS: 'DOWNLOAD_PAY_STUBS_DATA_SUCCESS',
  DOWNLOAD_PAY_STUBS_DATA_FAILURE: 'DOWNLOAD_PAY_STUBS_DATA_FAILURE',
  DOWNLOAD_PAY_STUBS_DATA_LOADER: 'DOWNLOAD_PAY_STUBS_DATA_LOADER',

  GET_EMP_OFFCYCLE_SUCCESS: 'GET_EMP_OFFCYCLE_SUCCESS',
  GET_EMP_OFFCYCLE_FAILURE: 'GET_EMP_OFFCYCLE_FAILURE',
  GET_EMP_OFFCYCLE_LOADER: 'GET_EMP_OFFCYCLE_LOADER',

  PROCESS_OFFCYCLE_SUCCESS: 'PROCESS_OFFCYCLE_SUCCESS',
  PROCESS_OFFCYCLE_FAILURE: 'PROCESS_OFFCYCLE_FAILURE',
  PROCESS_OFFCYCLE_LOADER: 'PROCESS_OFFCYCLE_LOADER',

  EMAIL_W4_DATA_SUCCESS: 'EMAIL_W4_DATA_SUCCESS',
  EMAIL_W4_DATA_FAILURE: 'EMAIL_W4_DATA_FAILURE',
  EMAIL_W4_DATA_LOADER: 'EMAIL_W4_DATA_LOADER',

  GET_TAX_DOC_DETAILS_SUCCESS: 'GET_TAX_DOC_DETAILS_SUCCESS',
  GET_TAX_DOC_DETAILS_FAILURE: 'GET_TAX_DOC_DETAILS_FAILURE',
  GET_TAX_DOC_DETAILS_LOADER: 'GET_TAX_DOC_DETAILS_LOADER',

  SEND_TAX_DOC_MAIL_SUCCESS: 'SEND_TAX_DOC_MAIL_SUCCESS',
  SEND_TAX_DOC_MAIL_FAILURE: 'SEND_TAX_DOC_MAIL_FAILURE',
  SEND_TAX_DOC_MAIL_LOADER: 'SEND_TAX_DOC_MAIL_LOADER',
};
