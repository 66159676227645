/**
 * Root Reducer
 */
import { combineReducers } from 'redux';
import { expenses } from './expensesReducer';
import { timeOff } from './timeOffReducer';
import { timeSheet } from './timeSheetReducer';
import { serviceRequests } from './serviceRequestReducer';
import { profile } from './profileReducer';
import { mbo } from './mboReducer';
import { oneonone } from './oneononeReducer';
import { globalReducer } from './globalReducer';
import { onBoarding } from './onBoardingReducer';
import { reports } from './reportsReducer';
import { benefitEnrollment } from './benefitEnrollmentReducer';
import { payRoll } from './payRollReducer';
import { loggedIn } from './loggedInReducer';
import { ATS } from './ATSReducer';
import { offboard } from './offboardingReducer';
import { i9 } from './i9Reducer';
import { projectMaintenanceReducer } from './projectMaintenanceReducer';
import { MDM } from './MDMReducer';
import { resignation } from './resignationReducer';
import { LeaveManagement } from './leaveManagement';
import { Templates } from './templatesReducer';
import { changePassword } from './changePassword';
import { ProjectManagement } from './projectManagementReducer';
import { payRollDeductions } from './PayrollDedutionReducer';

export default combineReducers({
  expenses,
  timeOff,
  timeSheet,
  serviceRequests,
  profile,
  mbo,
  oneonone,
  globalReducer,
  onBoarding,
  reports,
  benefitEnrollment,
  payRoll,
  loggedIn,
  ATS,
  offboard,
  i9,
  projectMaintenanceReducer,
  MDM,
  resignation,
  LeaveManagement,
  Templates,
  changePassword,
  ProjectManagement,
  payRollDeductions,
});
