
export const actionTypes = {
    GET_ONEONONE_SUCCESS:"GET_ONEONONE_SUCCESS",
    GET_ONEONONE_FAILURE:"GET_ONEONONE_FAILURE",
    POST_ONEONONE_SUCCESS:"POST_ONEONONE_SUCCESS",
    POST_ONEONONE_FAILURE:"POST_ONEONONE_FAILURE",
    GET_ONEONONE_LOADER:"GET_ONEONONE_LOADER",
    POST_NEW_ONEONONE_LOADER:"POST_NEW_ONEONONE_LOADER",
    POST_NEW_COMMENT_LOADER:"POST_NEW_COMMENT_LOADER"
}

 