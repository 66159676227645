import { endpoints } from '../config/endpoints/index';
import request from '../config/axios';

/**
 * Functions used to issue AJAX requests and manage responses.
 * All of the included methods use the Axios library for Promise-based requests.
 */
export const LoginServices = {
  ResetPaswordPost,
};

function ResetPaswordPost(data) {
  return request({
    method: 'post',
    url: endpoints.ResetPassword,
    data: data,
  }).then((resp) => {
    return resp;
  });
}
