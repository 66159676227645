import { Plugins } from '@capacitor/core';

import { OAuth2Client } from '@byteowls/capacitor-oauth2';

import { loginActions } from '../../actions/loginAction';

export default function oAuthAuthentication(dispatch, authType) {
  const microsoftOAuthOptions = {
    // prod_appId:46d53afe-366e-47bd-a3a4-440397b0593f
    // authorizationBaseUrl: 'https://login.microsoftonline.com/83ca9fb6-128c-4868-8f93-946ddc16ae66/oauth2/v2.0/authorize',
    // accessTokenEndpoint: 'https://login.microsoftonline.com/83ca9fb6-128c-4868-8f93-946ddc16ae66/oauth2/v2.0/token',

    appId: window.microsoftAppID,
    authorizationBaseUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
    // authorizationBaseUrl:
    //   'https://login.microsoftonline.com/83ca9fb6-128c-4868-8f93-946ddc16ae66/oauth2/v2.0/authorize',

    accessTokenEndpoint: '',
    // scope: 'https://graph.microsoft.com/.default',
    // scope: 'openId,User.Read',
    scope: window.scope,
    responseType: 'token',
    logsEnabled: true,
    web: {
      redirectUrl: window.microsoftRedirectionUrl,
    },
    android: {
      pkceEnabled: true,
      responseType: 'code',
      redirectUrl: 'com.evonsyspeoplehcm.app://oauth/auth',
      accessTokenEndpoint: 'https://login.microsoftonline.com/common/oauth2/v2.0/token',
      // accessTokenEndpoint:
      //   'https://login.microsoftonline.com/83ca9fb6-128c-4868-8f93-946ddc16ae66/oauth2/v2.0/token',

      // handleResultOnNewIntent: true,
      // handleResultOnActivityResult: true,
    },
    ios: {
      pkceEnabled: true,
      responseType: 'code',
      redirectUrl: 'com.evonsyspeoplehcm.app://oauth/auth',
      accessTokenEndpoint: 'https://login.microsoftonline.com/common/oauth2/v2.0/token',
      // accessTokenEndpoint:
      //   'https://login.microsoftonline.com/83ca9fb6-128c-4868-8f93-946ddc16ae66/oauth2/v2.0/token',
    },
  };

  const googleOAuthOptions = {
    appId: '168603478498-stbuq3ert1l0q4aovrci5sc2fhbod2tj.apps.googleusercontent.com',
    authorizationBaseUrl: 'https://accounts.google.com/o/oauth2/auth',
    //accessTokenEndpoint: 'https://www.googleapis.com/oauth2/v4/token',
    responseType: 'token',
    scope: 'profile email',
    resourceUrl: null,
    accessTokenEndpoint: null,

    web: {
      redirectUrl: 'https://hcmtrail.evonsys.com/',
      //redirectUrl: 'http://localhost:3000',
    },
    android: {
      pkceEnabled: true,
      responseType: 'code',
      redirectUrl: 'com.evonsyspeoplehcm.app',
      handleResultOnNewIntent: true,
      handleResultOnActivityResult: true,
    },
    ios: {
      pkceEnabled: true,
      responseType: 'code',
      redirectUrl: 'com.evonsyspeoplehcm.app',
    },
  };

  const onOAuthBtnClick = async () => {
    OAuth2Client.authenticate(
      authType === 'microsoft' ? microsoftOAuthOptions : googleOAuthOptions
    ).then((response) => {
      let accessToken =
        response.access_token ||
        response.authorization_response ||
        response.access_token_response;

      if (typeof accessToken === 'string') {
        localStorage.setItem('accessToken', accessToken);
      } else {
        localStorage.setItem(
          'accessToken',
          accessToken.access_token || accessToken['/access_token']
        );
      }
      dispatch && dispatch(loginActions.IsUserLoggedIn(true));
      dispatch && dispatch(loginActions.loginLoader({authType}));
    });
  };

  const onLogoutClick = () => {
    // localStorage.clear();
    // dispatch && dispatch(loginActions.IsUserLoggedIn(false));
    OAuth2Client.logout(authType === 'microsoft' ? microsoftOAuthOptions : googleOAuthOptions)
      .then(() => {
        localStorage.clear();
        dispatch && dispatch(loginActions.IsUserLoggedIn(false));
      })
      .catch((reason) => {
        console.error('OAuth logout failed', reason);
      });
  };

  return {
    onOAuthBtnClick,
    onLogoutClick,
  };
}
