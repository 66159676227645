import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import confused from '../../images/confused_low.png';

function LazyErrorComponent() {
  const theme = useTheme();

  const mobileMatchMediaQuery = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper
      style={{
        marginTop: '85px',
        marginLeft: !mobileMatchMediaQuery ? '70px' : '16px',
        marginRight: '16px',
      }}>
      <Box p={2} m={2}>
        <Grid
          container
          alignContent="center"
          style={{ height: '40vh' }}
          alignItems="center"
          justifyContent={mobileMatchMediaQuery ? 'space-between' : 'center'}>
          <Grid item xs={2}>
            <img
              src={confused}
              style={{ width: mobileMatchMediaQuery ? '10vh' : '20vh', marginBottom: '16px' }}
              alt="confused"
            />
          </Grid>
          <Grid item xs={8} md={2}>
            <Typography variant="subtitle1">Something went wrong</Typography>
            <Button
              color="primary"
              size="small"
              variant="outlined"
              onClick={() => {
                window.location.reload();
              }}>
              Reload
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default LazyErrorComponent;
