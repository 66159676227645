import React, { Suspense, lazy, useEffect } from 'react';

import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
// import {getCLS, getFID, getLCP} from 'web-vitals';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import './App.scss';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useDarkMode } from '../src/config/themes/themeSwitcher';
import LazyLoader from './components/utils/lazyLoader';

import { ErrorBoundary } from 'react-error-boundary';
import LazyErrorComponent from './components/utils/lazyErrorComponent';

import LoginPage from './components/pages/loginPage';

import { useLocation } from 'react-router-dom';
import Signin from './components/login/loginPage';
import { Paper, Typography } from '@mui/material';

import Backdrop from '@mui/material/Backdrop';

import { FileScanningLoader } from './components/utils/fileScanningloader';
import { loginActions } from './actions/loginAction';
import useMediaQuery from '@mui/material/useMediaQuery';

/*components*/

const ExpensesPage = lazy(() => import('./components/pages/expenses'));
const MiniDrawer = lazy(() => import('./components/utils/header'));
const TimeOffPage = lazy(() => import('./components/pages/timeoff'));
const TimeSheetPage = lazy(() => import('./components/pages/timesheet'));
const Profile = lazy(() => import('./components/pages/profile'));
const ServiceRequests = lazy(() => import('./components/pages/serviceRequests'));
const Pagenotfound = lazy(() => import('./components/pages/pagenotfound'));
const MBOPage = lazy(() => import('./components/pages/mbo'));
const TeamPage = lazy(() => import('./components/pages/team'));
const OnBoarding = lazy(() => import('./components/pages/onboarding'));
const ItGlobal = lazy(() => import('./components/pages/itGlobal'));
const reports = lazy(() => import('./components/pages/reports'));
const BenefitEnrollmentPage = lazy(() => import('./components/pages/benefitEnrollment'));
const PayRollPage = lazy(() => import('./components/pages/payRoll'));
const ATS = lazy(() => import('./components/pages/ATS'));
const ProjectMaintenance = lazy(() => import('./components/pages/ProjectMaintenance'));
const LeaveManagement = lazy(() => import('./components/pages/leaveManagement'));
const Templates = lazy(() => import('./components/pages/templates'));
const FinanceManager = lazy(() => import('./components/pages/FinanceManager'));
const Jobs = lazy(() => import('./components/pages/Jobs'));
const ApplicantFromLinkedin = lazy(() => import('./components/pages/ApplicantFromLinkedin'));
const userPayrollPage = lazy(() => import('./components/pages/userPayrollPage'));
const RegularPay = lazy(() => import('./components/pages/RegularPayPage'));
const OffCyclePage = lazy(() => import('./components/pages/offCyclePage'));
const PayrollTaxDocuments = lazy(() => import('./components/pages/PayrollTaxDocuments'));
const DashBoard = lazy(() => import('./components/pages/DashBoard'));
const ProjectManagement = lazy(() => import('./components/pages/ProjectManagement'));
const ProjectManagementCreate = lazy(() =>
  import('./components/ProjectManagement/CreateProject')
);
const PreivewPMcase = lazy(() => import('./components/ProjectManagement/PreviewPMCase'));
const ResourceNeed = lazy(() => import('./components/ProjectManagement/CreateResourceNeed'));
const PayrollDeduction = lazy(() => import('./components/pages/PayrollDeduction'));
const AddDeductions = lazy(() => import('./components/PayrollDeduction/AddDeductions'));
const CloseProjectPM = lazy(() => import('./components/ProjectManagement/CloseProject'));
const UpdateProject = lazy(() => import('./components/ProjectManagement/UpdateProject'));

// import PerformanceAppraisal from './components/pages/performanceappraisal';

// getCLS(console.log);
// getFID(console.log);
// getLCP(console.log);

const SSOLogin = window.isSSO;

const PrivateRoute = ({ component: Component, name, ...rest }) => {
  let getModules = localStorage.getItem('modules')
    ? localStorage.getItem('modules').trim().split(',')
    : '';

  getModules.push('dashboard');
  return (
    <Route
      {...rest}
      render={(props) =>
        getModules.indexOf(name) !== -1 ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/404' }} />
        )
      }
    />
  );
};

const useStyles = makeStyles(() => {
  const theme = useTheme();

  return {
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),

      // necessary for content to be below app bar
      [theme.breakpoints.up('sm')]: {
        ...theme.mixins.toolbar,
      },
    },
    content: {
      flexGrow: 1,
      // padding: theme.spacing(2),
      marginTop: '70px',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(9),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0),
      },
    },
    shiftTextLeft: {
      marginLeft: '0px',
    },
    shiftTextRight: {
      marginLeft: '180px',
    },
    appBodyBackground: {
      backgroundColor: theme.palette.grey[100],
    },
    loaderScanning: {
      display: 'flex',
      alignItems: 'end',
    },
  };
});

function BodyContent({ setIsJobsEnabled }) {
  const theme = useTheme();
  const classes = useStyles();
  const isToolBarOpen = useSelector((state) => state.globalReducer.isToolBarOpen) === true;
  const location = useLocation();

  useEffect(() => {
    setIsJobsEnabled(location?.pathname.includes('/jobs'));
  }, [location.pathname]);
  const mobileMatchMediaQuery = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <main
      style={{
        marginLeft: isToolBarOpen && !mobileMatchMediaQuery ? '166px' : '0px',
      }}
      className={clsx(
        { [classes.content]: true },
        // { [classes.shiftTextLeft]: !isToolBarOpen },
        // { [classes.shiftTextRight]: isToolBarOpen },
        { [classes.appBodyBackground]: true }
      )}>
      {/* <div className={classes.toolbar} /> */}
      <Switch>
        <PrivateRoute exact path="/expenses" name="ExpensesPage" component={ExpensesPage} />
        <PrivateRoute exact path="/timeoff" name="TimeOffPage" component={TimeOffPage} />
        <PrivateRoute exact path="/timesheet" name="TimeSheetPage" component={TimeSheetPage} />
        <PrivateRoute exact path="/" name="Profile" component={Profile} />
        <Route exact path="/404" component={Pagenotfound} />
        <PrivateRoute
          exact
          path="/servicerequests"
          name="ServiceRequests"
          component={ServiceRequests}
        />
        <PrivateRoute exact path="/MBOPage" name="MBOPage" component={MBOPage} />
        <PrivateRoute exact path="/SRFulfilment" name="SRFulfilment" component={ItGlobal} />
        <PrivateRoute exact path="/team" name="Team" component={TeamPage} />
        <PrivateRoute exact path="/onboarding" name="OnBoarding" component={OnBoarding} />
        <PrivateRoute exact path="/reports" name="reports" component={reports} />
        <PrivateRoute exact path="/templates" name="templates" component={Templates} />
        <PrivateRoute
          exact
          path="*/remuneration"
          name="remuneration"
          component={userPayrollPage}
        />

        <PrivateRoute
          exact
          path="/financeManager"
          name="financeManager"
          component={FinanceManager}
        />

        <PrivateRoute
          exact
          path="/benefitenrollment"
          name="BenefitEnrollment"
          component={BenefitEnrollmentPage}
        />

        <PrivateRoute exact path="*/PayRoll" name="PayRoll" component={PayRollPage} />
        <PrivateRoute
          exact
          path="*/PayRoll/TaxDocuments"
          name="PayRoll"
          component={PayrollTaxDocuments}
        />
        <PrivateRoute
          exact
          path="*/PayRoll/RegularPay"
          name="PayRoll"
          component={RegularPay}
        />
        <PrivateRoute
          exact
          path="*/PayRoll/Regularpay/:payRollCaseId"
          name="PayRoll"
          component={RegularPay}
        />

        <PrivateRoute
          exact
          path="*/PayRoll/:payrollType"
          name="PayRoll"
          component={OffCyclePage}
        />
        <PrivateRoute
          exact
          path="*/PayRoll/:payrollType/:payRollCaseId"
          name="PayRoll"
          component={OffCyclePage}
        />

        <PrivateRoute
          exact
          path="*/remuneration/:payrollPage"
          name="remuneration"
          component={userPayrollPage}
        />
        <PrivateRoute exact path="/ATS" name="ATS" component={ATS} />
        <PrivateRoute
          exact
          path="/LeaveManagement"
          name="LeaveManagement"
          component={LeaveManagement}
        />

        <PrivateRoute
          exact
          path="/projectMaintenance"
          name="ProjectMaintenance"
          component={ProjectMaintenance}
        />

        <PrivateRoute exact path="/dashboard" name="dashboard" component={DashBoard} />
        <PrivateRoute
          exact
          path="/ProjectManagement"
          name="ProjectManagement"
          component={ProjectManagement}
        />

        <PrivateRoute
          exact
          path="/ProjectManagement/Create"
          name="ProjectManagement"
          component={ProjectManagementCreate}
        />

        <PrivateRoute
          exact
          path="/ProjectManagement/CloseProject/:caseId"
          name="ProjectManagement"
          component={CloseProjectPM}
        />

        <PrivateRoute
          exact
          path="/ProjectManagement/update/:caseId/:type"
          name="ProjectManagement"
          component={UpdateProject}
        />
        <PrivateRoute
          exact
          path="/ProjectManagement/savedcase/:savedCaseID/:type"
          name="ProjectManagement"
          component={UpdateProject}
        />
        <PrivateRoute
          exact
          path="/ProjectManagement/Create/:caseId/:type"
          name="ProjectManagement"
          component={ProjectManagementCreate}
        />
        <PrivateRoute
          exact
          path="/ProjectManagement/:caseId/:type"
          name="ProjectManagement"
          component={PreivewPMcase}
        />
        <PrivateRoute
          exact
          path="/ProjectManagement/ResourceNeed/:mode/:caseId/:type"
          name="ProjectManagement"
          component={ResourceNeed}
        />
        <PrivateRoute
          exact
          path="/PostTaxDeductions"
          name="PostTaxDeductions"
          component={PayrollDeduction}
        />

        <PrivateRoute
          exact
          path="/PostTaxDeductions/addDeduction"
          name="PostTaxDeductions"
          component={AddDeductions}
        />
      </Switch>
    </main>
  );
}

// move to bootstrap-appseed.js
// if (process?.env?.NODE_ENV === 'development') {
//   window.localStorage.setItem(
//     'modules',
//     'ExpensesPage,TimeOffPage,TimeSheetPage,ServiceRequests,Jobs,PerformanceAppraisal,Profile,MBOPage,OneOnOne,Team,SRFulfilment,OnBoarding,reports,BenefitEnrollment,PayRoll,LoginPage,ATS,ProjectMaintenance,LeaveManagement,financeManager,templates,remuneration'
//   );
//   // window.localStorage.setItem('modules','ExpensesPage,TimeOffPage,TimeSheetPage,ServiceRequests,PerformanceAppraisal,Profile');
//   // localStorage.setItem('pyUser', 'srivardhan.gadepalli@evonsys.com');
//   // localStorage.setItem('pyUser', 'manasa@minori.com:rules');
//   // localStorage.setItem('pyUser', 'indrakiranreddy.challa@evonsys.com');
//   // localStorage.setItem('pyUser', 'lakshmipriya.samantaray@evonsys.com');
//   // localStorage.setItem('pyUser', 'satishkumar.tekkali@evonsys.com');
//   // localStorage.setItem('pyUser', 'srivardhan.gadepalli@evonsys.com');
//   // localStorage.setItem('pyUser', 'Manasa.guduri@evonsys.com');
//   // localStorage.setItem('pyUser', 'prithvirajkumar123@gmail.com');
//   // localStorage.setItem('pyUser', 'vijayalakshmi.m@evonsys.com');
//   // localStorage.setItem('pyUser', 'harish.bandlamudi@evonsys.com');
//   // localStorage.setItem('pyUser', 'jettkumar.methri@evonsys.com');
//   // localStorage.setItem('pyUser', 'muralimohan.rapuri@evonsys.com');
//   // localStorage.setItem('pyUser', 'madhusudhan.tanukonda@inspired.com');

//   localStorage.setItem('pyUser', 'ryancoogler1234@caterpillar.com:rules');

//   localStorage.setItem('pyRole', 'R3JvdXBIQ01NYW5hZ2Vy');
//   //localStorage.setItem('pyUser', 'hcmuser1@hotmail.com');
//   localStorage.setItem('isDeptQueueEnabled', 'true');
//   localStorage.setItem('deptQueueLabel', 'IT Global');
//   localStorage.setItem('hcmRole', 'VGVhbVJlYWN0SVRHbG9iYWxNYW5hZ2Vy');
// }

function MainContainer({
  setIsJobsEnabled,
  isUserLoggedIn,
  theme,
  isJobsEnabled,
  toggleTheme,
}) {
  const location = useLocation();

  useEffect(() => {
    setIsJobsEnabled(location?.pathname.includes('/jobs'));
  }, [location.pathname]);

  return (
    <React.Fragment>
      {isJobsEnabled ? (
        <Switch>
          <Jobs exact path="/jobs" name="Jobs" component={Jobs} />
          <ApplicantFromLinkedin
            exact
            path="/jobs/:id"
            name="Jobs"
            component={ApplicantFromLinkedin}
          />
        </Switch>
      ) : (
        <React.Fragment>
          {isUserLoggedIn ? (
            <React.Fragment>
              {!isJobsEnabled && (
                <MiniDrawer customTheme={theme} setCustomTheme={toggleTheme} />
              )}

              <ErrorBoundary fallback={<LazyErrorComponent />}>
                <Suspense fallback={<LazyLoader />}>
                  <BodyContent setIsJobsEnabled={setIsJobsEnabled} />
                </Suspense>
              </ErrorBoundary>
            </React.Fragment>
          ) : (
            <LoginPage SSOLogin={SSOLogin} Signin={Signin} />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
export default function App() {
  const [theme, toggleTheme, themeConfig, color] = useDarkMode();
  const classes = useStyles();
  const [isJobsEnabled, setIsJobsEnabled] = React.useState(false);
  const dispatch = useDispatch();

  const isUserLoggedIn = useSelector((state) => state.loggedIn.isLoggedIn);

  const uplaodedFileScanLoader = useSelector((state) => state.loggedIn.uplaodedFileScanLoader);

  // to be removed after theming

  // if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
  //   localStorage.setItem('theme', 'light');
  // } else {
  //   localStorage.setItem('theme', 'dark');
  // }

  useEffect(() => {

    if(process.env.NODE_ENV === 'development')return;
    
        const handleKeyDown = (event) => {
          // Check if Ctrl+Shift+C is pressed
          if (
            (event.ctrlKey && event.shiftKey && event.key?.toLowerCase() === 'c') ||
            (event.metaKey && event.shiftKey && event.key?.toLowerCase() === 'c')
          ) {
            alert('Due to security reason,debug mode is not allowed.');
            event.preventDefault();
            // Prevent the default behavior (e.g., opening browser console)
            // Optionally, you can show a message or perform some other action here
          }
        };
    
        // Attach the event listener when the component mounts
        window.addEventListener('keydown', handleKeyDown);
    
        // Remove the event listener when the component unmounts
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, []);

  localStorage.setItem('theme', 'light');

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') return;

    const handleKeyDown = (event) => {
      // Check if Ctrl+Shift+C is pressed
      if (
        (event.ctrlKey && event.shiftKey && event.key?.toLowerCase() === 'c') ||
        (event.metaKey && event.shiftKey && event.key?.toLowerCase() === 'c') ||
        event.key === 'F12'
      ) {
        alert('Due to security reason,debug mode is not allowed.');
        event.preventDefault();
        // Prevent the default behavior (e.g., opening browser console)
        // Optionally, you can show a message or perform some other action here
      }
    };

    // Attach the event listener when the component mounts
    window.addEventListener('keydown', handleKeyDown);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (window.isSSO && isUserLoggedIn) {
      dispatch(loginActions.ProfilePic());
      dispatch(loginActions.ProfilePicLoader());
    }
  }, [dispatch, isUserLoggedIn]);

  return (
    <Paper style={color}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={uplaodedFileScanLoader}>
        <div className={classes.loaderScanning}>
          <Typography variant="h4">Scanning File...</Typography>
          <FileScanningLoader />
        </div>
      </Backdrop>

      <HashRouter>
        <Suspense fallback={<LazyLoader isJobsEnabled={isJobsEnabled} />}>
          <ThemeProvider theme={themeConfig}>
            <MainContainer
              theme={theme}
              isUserLoggedIn={isUserLoggedIn}
              setIsJobsEnabled={setIsJobsEnabled}
              isJobsEnabled={isJobsEnabled}
              toggleTheme={toggleTheme}
            />
          </ThemeProvider>
        </Suspense>
      </HashRouter>
    </Paper>
  );
}

// <Route exact path="/servicerequests" component={ServiceRequests} />
// <PrivateRoute exact path="/performanceappraisal" name = "PerformanceAppraisal" component={PerformanceAppraisal} />
