export const actionTypes = {
  POST_REPORTS_SUCCESS: 'POST_REPORTS_SUCCESS',
  POST_REPORTS_FAILURE: 'POST_REPORTS_FAILURE',
  GET_REPORTS_SUCCESS: 'GET_REPORTS_SUCCESS',
  GET_REPORTS_FAILURE: 'GET_REPORTS_FAILURE',
  GET_REPORTS_LOADER: 'GET_REPORTS_LOADER',
  SHOW_LOADER_REPORTS: 'SHOW_LOADER_REPORTS',

  SET_REPORTS_DATA: 'SET_REPORTS_DATA',

  GET_FILTER_DATA_SUCCESS: 'GET_FILTER_DATA_SUCCESS',
  GET_FILTER_DATA_LOADER: 'GET_FILTER_DATA_LOADER',
  GET_FILTER_DATA_FAILURE: 'GET_FILTER_DATA_FAILURE',
  CLEAR_FILTER_DATA: 'CLEAR_FILTER_DATA',

  SEND_REPORT_THROUGH_MAIL_SUCCESS: 'SEND_REPORT_THROUGH_MAIL_SUCCESS',
  SEND_REPORT_THROUGH_MAIL_FAILURE: 'SEND_REPORT_THROUGH_MAIL_FAILURE',
  SEND_REPORT_THROUGH_MAIL_LOADER: 'SEND_REPORT_THROUGH_MAIL_LOADER',
};
