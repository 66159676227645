import { actionTypes } from '../actions/actionTypes/offboardingActionTypes';

let initialState = {
  offboardingBasicData: {},
  getOffboardingLoader: false,
  createOffboardLoader: false,
  offboardTaskDetails: {},
};

/**
 * Redux reducers.
 * Used to update state in the store after actions are issued.
 */
export function offboard(state = initialState, action) {
  // console.log(action);
  switch (action.type) {
    case actionTypes.GET_OFFBOARDING_SUCCESS:
      return {
        ...state,
        offboardingBasicData: action.cases || {},
        getOffboardingLoader: false,
        offboardTaskDetails: {},
      };
    case actionTypes.GET_OFFBOARDING_FAILURE:
      return {
        ...state,
        offboardTaskDetails: {},
        offboardingBasicData: {},
        getOffboardingLoader: false,
      };

    case actionTypes.GET_OFFBOARDING_LOADER:
      return {
        ...state,
        getOffboardingLoader: true,
      };

    case actionTypes.CREATE_OFFBOARD_SUCCESS:
      return {
        ...state,
        offboardTaskDetails: action.cases || {},
        createOffboardLoader: false,
      };
    case actionTypes.CREATE_OFFBOARD_FAILURE:
      return {
        ...state,
        offboardTaskDetails: {},
        createOffboardLoader: false,
      };

    case actionTypes.CREATE_OFFBOARD_LOADER:
      return {
        ...state,
        createOffboardLoader: true,
      };

    case actionTypes.CLEAR_OFFBOARD_DATA:
      return {
        ...state,
        offboardTaskDetails: {},
      };
    case actionTypes.SET_COMMUNICATION_DATA:
      return {
        ...state,
        offboardTaskDetails: action.data,
      };

    default:
      return state;
  }
}
