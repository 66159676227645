import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import LockIcon from '@mui/icons-material/Lock';
import { useDispatch, useSelector } from 'react-redux';
import { loginActions } from '../../actions/loginAction';
import { LoadingButton } from '@mui/lab';
import { startTimer } from '../utils/timer';
const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  otp: Yup.string().when('isOTPSent', {
    is: true,
    then: Yup.string().required('Required').min(8, 'Invalid OTP').max(8, 'Invalid OTP'),
  }),
});

export const ForgotPassword = ({ setForgotPasswordEnable }) => {
  const dispatch = useDispatch();

  const resetPWDLoader = useSelector((state) => state.loggedIn.resetPWDLoader);
  const ResetPWDData = useSelector((state) => state.loggedIn.ResetPWDData);

  const [isResedOTPEnabled, setResedOTPEnabled] = React.useState(false);

  useEffect(() => {
    return () => {
      dispatch(loginActions.clearPWDData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (ResetPWDData.IsValidOTP === 'true') {
      setForgotPasswordEnable(false);
    }

    let OTPTimeLimit = parseFloat(ResetPWDData.OTPTimeLimit || 0);

    if (OTPTimeLimit) {
      setResedOTPEnabled(true);
      disableResedOTP(OTPTimeLimit * 1000);
      timer(OTPTimeLimit, '#timerOTP');
    }
  }, [ResetPWDData]);

  const disableResedOTP = (time) => {
    setTimeout(() => {
      setResedOTPEnabled(false);
    }, time);
  };

  const resendOTP = (values) => {
    dispatch(loginActions.getOTPResetPasswordLoader());
    dispatch(
      loginActions.getOTPResetPassword({
        actionType: 'ResetPassword',
        pyUserIdentifier: values.email,
      })
    );
  };

  const timer = (time, eleID) => {
    let ele = document.querySelector(eleID);
    startTimer(time, ele);
  };

  return (
    <Box style={{ marginTop: '-150px' }}>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={{
          email: ResetPWDData.pyUserIdentifier || '',
          otp: '',
          isOTPSent: Boolean(
            ResetPWDData?.status?.toLowerCase() === 'success' ||
              ResetPWDData?.IsValidOTP === 'false'
          ),
        }}
        onSubmit={(values) => {
          dispatch(loginActions.getOTPResetPasswordLoader());
          dispatch(
            loginActions.getOTPResetPassword(
              values.isOTPSent
                ? {
                    actionType: 'UpdatePassword',
                    OTP: values.otp,
                    pyUserIdentifier: values.email,
                  }
                : {
                    actionType: 'ResetPassword',
                    pyUserIdentifier: values.email,
                  }
            )
          );
        }}>
        {({ errors, handleChange, values, touched }) => (
          <Form>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}>
                  <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <LockIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Forgot your password?
                  </Typography>

                  <Box sx={{ mt: 1, width: '75%' }}>
                    <TextField
                      margin="normal"
                      fullWidth
                      variant="standard"
                      onChange={handleChange}
                      error={Boolean(errors.email && touched.email)}
                      helperText={errors.email && touched.email && errors.email}
                      disabled={values.isOTPSent}
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                    />
                    <div>
                      {values.isOTPSent && (
                        <Box>
                          <TextField
                            margin="normal"
                            fullWidth
                            variant="standard"
                            onChange={handleChange}
                            error={Boolean(errors.otp && touched.otp)}
                            helperText={errors.otp && touched.otp && errors.otp}
                            id="otp"
                            label="OTP"
                            name="otp"
                            autoComplete="otp"
                            autoFocus
                          />
                        </Box>
                      )}
                      <Typography variant="caption" id="timerOTP"></Typography>
                    </div>

                    <LoadingButton
                      loading={resetPWDLoader}
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}>
                      {values.isOTPSent ? 'Reset Password' : 'Send OTP'}
                    </LoadingButton>
                    <Grid container justifyContent={'space-between'}>
                      <LoadingButton
                        disabled={resetPWDLoader}
                        onClick={() => {
                          setForgotPasswordEnable(false);
                        }}>
                        {'Sign in'}
                      </LoadingButton>

                      {values.isOTPSent && (
                        <LoadingButton
                          disabled={resetPWDLoader || isResedOTPEnabled}
                          onClick={() => {
                            resendOTP(values);
                          }}>
                          Resend OTP
                        </LoadingButton>
                      )}
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
