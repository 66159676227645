export const actionTypes = {
  PAYROLL_DEDUCTIONS_SUCCESS: 'PAYROLL_DEDUCTIONS_SUCCESS',
  PAYROLL_DEDUCTIONS_FAILURE: 'PAYROLL_DEDUCTIONS_FAILURE',
  PAYROLL_DEDUCTIONS_LOADER: 'PAYROLL_DEDUCTIONS_LOADER',

  ADD_NEW_DEDUCTIONS_SUCCESS: 'ADD_NEW_DEDUCTIONS_SUCCESS',
  ADD_NEW_DEDUCTIONS_FAILURE: 'ADD_NEW_DEDUCTIONS_FAILURE',
  ADD_NEW_DEDUCTIONS_LOADER: 'ADD_NEW_DEDUCTIONS_LOADER',

  DELETE_DEDUCTION_SUCCESS: 'DELETE_DEDUCTION_SUCCESS',
  DELETE_DEDUCTIONS_FAILURE: 'DELETE_DEDUCTIONS_FAILURE',
  DELETE_DEDUCTIONS_LOADER: 'DELETE_DEDUCTIONS_LOADER',

  GET_AUDIT_HISTORY_SUCCESS: 'GET_AUDIT_HISTORY_SUCCESS',
  GET_AUDIT_HISTORY_FAILURE: 'GET_AUDIT_HISTORY_FAILURE',
  GET_AUDIT_HISTORY_LOADER: 'GET_AUDIT_HISTORY_LOADER',
};
