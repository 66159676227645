export const actionTypes = {
  GET_PROFILE_DATA_SUCCESS: 'GET_PROFILE_DATA_SUCCESS',
  GET_PROFILE_DATA_FAILURE: 'GET_PROFILE_DATA_FAILURE',
  GET_PROFILE_DATA_LOADER: 'GET_PROFILE_DATA_LOADER',

  POST_PROFILE_DATA_SUCCESS: 'POST_PROFILE_DATA_SUCCESS',
  POST_PROFILE_DATA_FAILURE: 'POST_PROFILE_DATA_FAILURE',
  SHOW_LOADER_FOR_RESIGN_LOADER: 'SHOW_LOADER_FOR_RESIGN_LOADER',

  PERMENENT_ADDRESS_UPDATE_SUCCESS: 'PERMENENT_ADDRESS_UPDATE_SUCCESS',
  PERMENENT_ADDRESS_UPDATE_FAILURE: 'PERMENENT_ADDRESS_UPDATE_FAILURE',
  PERMENENT_ADDRESS_UPDATE_LOADER: 'PERMENENT_ADDRESS_UPDATE_LOADER',

  COMM_ADDRESS_UPDATE_SUCCESS: 'COMM_ADDRESS_UPDATE_SUCCESS',
  COMM_ADDRESS_UPDATE_FAILURE: 'COMM_ADDRESS_UPDATE_FAILURE',
  COMM_ADDRESS_UPDATE_LOADER: 'COMM_ADDRESS_UPDATE_LOADER',

  PRIMARY_PHONE_UPDATE_SUCCESS: 'PRIMARY_PHONE_UPDATE_SUCCESS',
  PRIMARY_PHONE_UPDATE_FAILURE: 'PRIMARY_PHONE_UPDATE_FAILURE',
  PRIMARY_PHONE_UPDATE_LOADER: 'PRIMARY_PHONE_UPDATE_LOADER',

  SECONDARY_PHONE_UPDATE_SUCCESS: 'SECONDARY_PHONE_UPDATE_SUCCESS',
  SECONDARY_PHONE_UPDATE_FAILURE: 'SECONDARY_PHONE_UPDATE_FAILURE',
  SECONDARY_PHONE_UPDATE_LOADER: 'SECONDARY_PHONE_UPDATE_LOADER',

  WHATSUP_PHONE_UPDATE_SUCCESS: 'WHATSUP_PHONE_UPDATE_SUCCESS',
  WHATSUP_PHONE_UPDATE_FAILURE: 'WHATSUP_PHONE_UPDATE_FAILURE',
  WHATSUP_PHONE_UPDATE_LOADER: 'WHATSUP_PHONE_UPDATE_LOADER',

  SKILL_UPDATE_SUCCESS: 'SKILL_UPDATE_SUCCESS',
  SKILLE_UPDATE_FAILURE: 'SKILLE_UPDATE_FAILURE',
  SKILL_UPDATE_LOADER: 'SKILL_UPDATE_LOADER',

  GET_ADDRESS_SUCCESS: 'GET_ADDRESS_SUCCESS',
  GET_ADDRESS_FAILURE: 'GET_ADDRESS_FAILURE',
  GET_ADDRESS_LOADER: 'GET_ADDRESS_LOADER',

  SET_DATA_PROFILE: 'SET_DATA_PROFILE',

  SET_ALL_PROFILE_BUTTONS_PROFILE: 'SET_ALL_PROFILE_BUTTONS_PROFILE',

  GET_ACCESS_TOKEN_SUCCESS: 'GET_ACCESS_TOKEN_SUCCESS',
  GET_ACCESS_TOKEN_FAILURE: 'GET_ACCESS_TOKEN_FAILURE',
  GET_ACCESS_TOKEN_LOADER: 'GET_ACCESS_TOKEN_LOADER',

  EMPLOYER_ID_SENDING_SUCCESS: 'EMPLOYER_ID_SENDING_SUCCESS',
  EMPLOYER_ID_SENDING_FAILURE: 'EMPLOYER_ID_SENDING_FAILURE',
  EMPLOYER_ID_SENDING_LOADER: 'EMPLOYER_ID_SENDING_LOADER',

  EMPLOYER_IAV_TOKEN_SUCCESS: 'EMPLOYER_IAV_TOKEN_SUCCESS',
  EMPLOYER_IAV_TOKEN_FAILURE: 'EMPLOYER_IAV_TOKEN_FAILURE',
  EMPLOYER_IAV_TOKEN_LOADER: 'EMPLOYER_IAV_TOKEN_LOADER',

  EMPLOYER_FS_SUCCESS: 'EMPLOYER_FS_SUCCESS',
  EMPLOYER_FS_FAILURE: 'EMPLOYER_FS_FAILURE',

  REMOVE_FS_SUCCESS: 'REMOVE_FS_SUCCESS',
  REMOVE_FS_FAILURE: 'REMOVE_FS_FAILURE',
  REMOVE_FS_LOADER: 'REMOVE_FS_LOADER',

  VERIFY_CUSTOMER_CREATION_SUCCESS: 'VERIFY_CUSTOMER_CREATION_SUCCESS',
  VERIFY_CUSTOMER_CREATION_FAILURE: 'VERIFY_CUSTOMER_CREATION_FAILURE',

  GET_KBA_QUESTIONS_SUCCESS: 'GET_KBA_QUESTIONS_SUCCESS',
  GET_KBA_QUESTIONS_FAILURE: 'GET_KBA_QUESTIONS_FAILURE',
  GET_KBA_QUESTIONS_LOADER: 'GET_KBA_QUESTIONS_LOADER',

  SEND_DOCUMENT_ID_LOADER: 'SEND_DOCUMENT_ID_LOADER',
  TEMP: 'TEMP',

  AVAILABLE_SLOTS_SUCCESS: 'AVAILABLE_SLOTS_SUCCESS',
  AVAILABLE_SLOTS_LOADER: 'AVAILABLE_SLOTS_LOADER',
  AVAILABLE_SLOTS_FAILURE: 'AVAILABLE_SLOTS_FAILURE',

  OVERRIDE_SLOTS_SUCCESS: 'OVERRIDE_SLOTS_SUCCESS',
  OVERRIDE_SLOTS_LOADER: 'OVERRIDE_SLOTS_LOADER',
  OVERRIDE_SLOTS_FAILURE: 'OVERRIDE_SLOTS_FAILURE',

  NOTAVAILABLE_SLOTS_SUCCESS: 'NOTAVAILABLE_SLOTS_SUCCESS',
  NOTAVAILABLE_SLOTS_LOADER: 'NOTAVAILABLE_SLOTS_LOADER',
  NOTAVAILABLE_SLOTS_FAILURE: 'NOTAVAILABLE_SLOTS_FAILURE',

  SET_SLOTS_AVAILABLE: 'SET_SLOTS_AVAILABLE',
  SET_OVERRIDE_DATA: 'SET_OVERRIDE_DATA',

  OVERRIDE_CALENDER_SUCCESS: 'OVERRIDE_CALENDER_SUCCESS',
  OVERRIDE_CALENDER_LOADER: 'OVERRIDE_CALENDER_LOADER',
  OVERRIDE_CALENDER_FAILURE: 'OVERRIDE_CALENDER_FAILURE',

  VERIFY_MICRO_DEPOSITS_SUCCESS: 'VERIFY_MICRO_DEPOSITS_SUCCESS',
  VERIFY_MICRO_DEPOSITS_LOADER: 'VERIFY_MICRO_DEPOSITS_LOADER',
  VERIFY_MICRO_DEPOSITS_FAILURE: 'VERIFY_MICRO_DEPOSITS_FAILURE',

  GET_PLAID_TOKEN_SUCCESS: 'GET_PLAID_TOKEN_SUCCESS',
  GET_PLAID_TOKEN_FAILURE: 'GET_PLAID_TOKEN_FAILURE',
  GET_PLAID_TOKEN_LOADER: 'GET_PLAID_TOKEN_LOADER',

  CLEAR_PLAID_TOKEN: 'CLEAR_PLAID_TOKEN',

  SEND_PLAID_PUBLIC_TOKEN_SUCCESS: 'SEND_PLAID_PUBLIC_TOKEN_SUCCESS',
  SEND_PLAID_PUBLIC_TOKEN_FAILURE: 'SEND_PLAID_PUBLIC_TOKEN_FAILURE',
  SEND_PLAID_PUBLIC_TOKEN_LOADER: 'SEND_PLAID_PUBLIC_TOKEN_LOADER',

  REMOVE_PLAID_ACCOUNT_SUCCESS: 'REMOVE_PLAID_ACCOUNT_SUCCESS',
  REMOVE_PLAID_ACCOUNT_FAILURE: 'REMOVE_PLAID_ACCOUNT_FAILURE',
  REMOVE_PLAID_ACCOUNT_LOADER: 'REMOVE_PLAID_ACCOUNT_LOADER',
};
