import { actionTypes } from '../actions/actionTypes/benefitEnrollmentActionType';

let initialState = {
  PremiumCalculations: {},
  getBenefitEnrolledData: {},
  QLECaseList: [],
  QLECaseData: {},
  PageIndexCount: 0,
  loaderForPostTeamEnrolledBenefits: false,
  loaderForGetTeamEnrolledBenefits: false,
  loaderForPremiumCalculations: false,
  getEnrolledBeneficiaries: [],
  benefitMenuOptions: [],
  newBenefitBasicData: {},
  QLEReasonsList: {},
  loaderForPostEnrolledBenefits: false,
  loaderForGettEnrolledBenefits: false,
  submit401kDetalsLoader: false,
  submit401kData: {},
  existing401KDetails: {},
  detailsOf401k: {},
  BeneficiariesDOBAndSSNDetails: {},
  BeneficiariesDOBAndSSNDetailsLoader: false,
  heading: 'Benefit Enrollment',
  newBeneficiaries: {
    Beneficiaries: [],
    pyID: '',
    Medical: {
      selectedPlane: [],
      selectedBeneficiariesList: [],
      selectedRows: [],
      isPolicynull: false,
      reason: '',
      BeneficiariesWithPremium: [],
      rows: [],
    },
    Vision: {
      selectedPlane: [],
      selectedBeneficiariesList: [],
      selectedRows: [],
      isPolicynull: false,
      reason: '',
      BeneficiariesWithPremium: [],
      rows: [],
    },
    Dental: {
      selectedPlane: [],
      selectedBeneficiariesList: [],
      selectedRows: [],
      isPolicynull: false,
      reason: '',
      BeneficiariesWithPremium: [],
      rows: [],
    },
  },
  view401kAudit: [],
  loaderForView401kAudit: false,
};

const dataWithId = (data) => {
  if (data) {
    let finalData = [...data];
    data &&
      data.forEach((item, index) => {
        finalData[index] = { ...item, id: index };
      });
    return finalData;
  }
};
/**
 * Redux reducers.
 * Used to update state in the store after actions are issued.
 */
export function benefitEnrollment(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_DATA_BE:
      return {
        ...state,
        heading: action?.data?.heading ? action?.data?.heading : state.heading,
        newBeneficiaries: {
          Beneficiaries: action.data.Beneficiaries,
          pyID: action.data.pyID,
          Medical: {
            selectedBeneficiariesList: action.data?.Medical?.selectedBeneficiariesList,
            selectedPlane: action.data?.Medical?.selectedPlane,
            selectedRows: action.data?.Medical?.selectedRows,
            isPolicynull: action.data?.Medical?.isPolicynull,
            BeneficiariesWithPremium: action.data?.Medical?.BeneficiariesWithPremium,
            reason: action.data?.Medical?.reason,
            rows: action.data?.Medical?.rows,
          },
          Vision: {
            selectedBeneficiariesList: action.data?.Vision?.selectedBeneficiariesList,
            selectedPlane: action.data?.Vision?.selectedPlane,
            selectedRows: action.data?.Vision?.selectedRows,
            isPolicynull: action.data?.Vision?.isPolicynull,
            BeneficiariesWithPremium: action.data?.Vision?.BeneficiariesWithPremium,
            reason: action.data?.Vision?.reason,
            rows: action.data?.Vision?.rows,
          },
          Dental: {
            selectedBeneficiariesList: action.data?.Dental?.selectedBeneficiariesList,
            selectedPlane: action.data?.Dental?.selectedPlane,
            selectedRows: action.data?.Dental?.selectedRows,
            isPolicynull: action.data?.Dental?.isPolicynull,
            BeneficiariesWithPremium: action.data?.Dental?.BeneficiariesWithPremium,
            reason: action.data?.Dental?.reason,
            rows: action.data?.Dental?.rows,
          },
        },
      };
    case actionTypes.GET_ENROLLED_BENEFITS_SUCCESS:
      return {
        ...state,
        getEnrolledBeneficiaries: action.benefitEnrolledData?.EnrolledBenefits || [],
        benefitMenuOptions: action.benefitEnrolledData?.BenefitOptionMenu || [],
        getBenefitEnrolledData: action.benefitEnrolledData,
        loaderForGettEnrolledBenefits: false,
        heading: 'Benefit Enrollment',
        submit401kData: {},
      };

    case actionTypes.GET_ENROLLED_BENEFITS_FAILURE:
      return {
        ...state,
        getEnrolledBeneficiaries: [],
        benefitMenuOptions: [],
        heading: 'Benefit Enrollment',
        loaderForGettEnrolledBenefits: false,
      };
    case actionTypes.POST_ENROLLED_BENEFITS_SUCCESS:
      return {
        ...state,
        newBenefitBasicData: action.cases || {},
        QLEReasonsList: action.cases || {},
        loaderForPostEnrolledBenefits: false,
        getEnrolledBeneficiaries: action.cases?.EnrolledBenefits || [],
        benefitMenuOptions: action.cases?.BenefitOptionMenu || [],
        loaderForGettEnrolledBenefits: false,
      };
    case actionTypes.POST_ENROLLED_BENEFITS_FAILURE:
      return {
        ...state,
        newBenefitBasicData: [],
        loaderForPostEnrolledBenefits: false,
        QLEReasonsList: {},
        getEnrolledBeneficiaries: [],
        benefitMenuOptions: [],
        loaderForGettEnrolledBenefits: false,
      };
    case actionTypes.GET_ENROLLED_BENEFITS_LOADER:
      return {
        ...state,
        loaderForGettEnrolledBenefits: true,
      };
    case actionTypes.POST_ENROLLED_BENEFITS_LOADER:
      return {
        ...state,
        loaderForPostEnrolledBenefits: true,
      };
    case actionTypes.GET_QLE_PREMIUM_CALCULATIONS_SUCCESS:
      return {
        ...state,
        PremiumCalculations: action.cases || {},
        loaderForPremiumCalculations: false,
        newBenefitEnrollment: action.cases || {},
        newBeneficiaries: {
          ...state.newBeneficiaries,
          Medical: {
            ...state.newBeneficiaries.Medical,
            BeneficiariesWithPremium: dataWithId(
              action.cases.newBenfitEnrollment.Medical?.selectedBeneficiariesList
            ),
            selectedPlane: action.cases.newBenfitEnrollment.Medical?.selectedPlane
              ? action.cases.newBenfitEnrollment.Medical?.selectedPlane
              : [],
          },
          Dental: {
            ...state.newBeneficiaries.Vision,
            BeneficiariesWithPremium: dataWithId(
              action.cases.newBenfitEnrollment.Dental?.selectedBeneficiariesList
            ),
            selectedPlane: action.cases.newBenfitEnrollment.Dental?.selectedPlane
              ? action.cases.newBenfitEnrollment.Dental?.selectedPlane
              : [],
          },
          Vision: {
            ...state.newBeneficiaries.Dental,
            BeneficiariesWithPremium: dataWithId(
              action.cases.newBenfitEnrollment.Vision?.selectedBeneficiariesList
            ),
            selectedPlane: action.cases.newBenfitEnrollment.Vision?.selectedPlane
              ? action.cases.newBenfitEnrollment.Vision?.selectedPlane
              : [],
          },
        },
      };
    case actionTypes.GET_QLE_PREMIUM_CALCULATIONS_FAILURE:
      return {
        ...state,
        loaderForPremiumCalculations: false,
      };
    case actionTypes.GET_PREMIUM_CALCULATIONS_LODER:
      return {
        ...state,
        loaderForPremiumCalculations: true,
      };
    case actionTypes.GET_PREMIUM_CALCULATIONS_SUCCESS:
      return {
        ...state,
        PremiumCalculations: action.cases || {},
        loaderForPremiumCalculations: false,
        newBenefitEnrollment: action.cases || {},
      };
    case actionTypes.GET_PREMIUM_CALCULATIONS_FAILURE:
      return {
        ...state,
        PremiumCalculations: {},
        loaderForPremiumCalculations: false,
        newBenefitEnrollment: {},
      };

    case actionTypes.GET_TEAM_ENROLLED_BENEFITS_SUCCESS:
      return {
        ...state,
        QLECaseList: action?.benefitEnrolledData?.QLECaseList || [],
        PageIndexCount: action?.benefitEnrolledData?.PageIndexCount,
        loaderForGetTeamEnrolledBenefits: false,
      };
    case actionTypes.GET_TEAM_ENROLLED_BENEFITS_FAILURE:
      return {
        ...state,
        QLECaseList: [],
        loaderForGetTeamEnrolledBenefits: false,
      };

    case actionTypes.GET_TEAM_ENROLLED_BENEFITS_LOADER:
      return {
        ...state,

        loaderForGetTeamEnrolledBenefits: true,
      };

    case actionTypes.POST_TEAM_ENROLLED_BENEFITS_SUCCESS:
      return {
        ...state,
        QLECaseData: action?.benefitEnrolledData || {},
        QLECaseList: action?.benefitEnrolledData?.QLEList,
        PageIndexCount: action?.benefitEnrolledData?.PageIndexCount,
        loaderForGetTeamEnrolledBenefits: false,

        loaderForPostTeamEnrolledBenefits: false,
      };
    case actionTypes.POST_TEAM_ENROLLED_BENEFITS_FAILURE:
      return {
        ...state,
        QLECaseData: {},
        loaderForGetTeamEnrolledBenefits: false,
        loaderForPostTeamEnrolledBenefits: false,
      };

    case actionTypes.OPEN_BE_CASE_TEAMS_SUCCESS:
      return {
        ...state,
        QLECaseData: action?.benefitEnrolledData || {},
        loaderForGetTeamEnrolledBenefits: false,

        loaderForPostTeamEnrolledBenefits: false,
      };
    case actionTypes.OPEN_BE_CASE_TEAMS_FAILURE:
      return {
        ...state,
        QLECaseData: {},
        loaderForGetTeamEnrolledBenefits: false,
        loaderForPostTeamEnrolledBenefits: false,
      };

    case actionTypes.POST_TEAM_ENROLLED_BENEFITS_LOADER:
      return {
        ...state,

        loaderForPostTeamEnrolledBenefits: true,
      };

    case actionTypes.SET_DATA_BE_TEAM:
      return {
        ...state,
        QLECaseData: {},
      };

    case actionTypes.GET_DETAILS_401K_SUCCESS:
      return {
        ...state,
        loaderForPostEnrolledBenefits: false,
        detailsOf401k: action?.benefitEnrolledData || {},
        heading: '401K Enrollment',
      };

    case actionTypes.GET_DETAILS_401K_FAILURES:
      return {
        ...state,
        loaderForPostEnrolledBenefits: false,
      };
    case actionTypes.SUBMIT_401K_DETAILS_SUCCESS:
      return {
        ...state,
        submit401kDetalsLoader: false,
        submit401kData: action?.benefitEnrolledData || {},
      };
    case actionTypes.SUBMIT_401K_DETAILS_FAILURE:
      return {
        ...state,
        submit401kDetalsLoader: false,
        submit401kData: {},
      };
    case actionTypes.SUBMIT_401K_DETAILS_LOADER:
      return {
        ...state,
        submit401kDetalsLoader: true,
      };

    case actionTypes.CHANGE_401K_PLAN_SUCCESS:
      return {
        ...state,
        loaderForPostEnrolledBenefits: false,
        detailsOf401k: action?.benefitEnrolledData || {},
        heading: '401K Enrollment',
      };

    case actionTypes.CHANGE_401K_PLAN_FAILURE:
      return {
        ...state,
        loaderForPostEnrolledBenefits: false,
      };

    case actionTypes.DISCONTINUE_PLAN_SUCCESS:
      return {
        ...state,
        loaderForPostEnrolledBenefits: false,
      };

    case actionTypes.DISCONTINUE_PLAN_FAILURE:
      return {
        ...state,
        loaderForPostEnrolledBenefits: false,
      };

    case actionTypes.SHOW_DOB_SSN_SUCCESS:
      return {
        ...state,
        BeneficiariesDOBAndSSNDetailsLoader: false,
        BeneficiariesDOBAndSSNDetails: action?.benefitEnrolledData || {},
      };

    case actionTypes.SHOW_DOB_SSN_FAILURE:
      return {
        ...state,
        BeneficiariesDOBAndSSNDetailsLoader: false,
      };

    case actionTypes.SHOW_DOB_SSN_LOADER:
      return {
        ...state,
        BeneficiariesDOBAndSSNDetailsLoader: true,
      };

    case 'RESET_VIEWDATA':
      return {
        ...state,
        BeneficiariesDOBAndSSNDetails: {},
      };

    case actionTypes.VIEW_401K_AUDIT_SUCCESS:
      return {
        ...state,
        view401kAudit: action.benefitEnrolledData || [],
        loaderForView401kAudit: false,
      };

    case actionTypes.VIEW_401K_AUDIT_LOADER:
      return {
        ...state,
        loaderForView401kAudit: true,
      };

    case actionTypes.VIEW_401K_AUDIT_FAILURE:
      return {
        ...state,
        loaderForView401kAudit: false,
      };

    default:
      return state;
  }
}
