export const actionTypes = {
  GET_OLDSERVICEREQUESTS_SUCCESS: 'GET_OLDSERVICEREQUESTS_SUCCESS',
  GET_OLDSERVICEREQUESTS_FAILURE: 'GET_OLDSERVICEREQUESTS_FAILURE',
  GET_GETUSERS_SUCCESS: 'GET_GETUSERS_SUCCESS',
  GET_GETUSERS_FAILURE: 'GET_GETUSERS_FAILURE',
  SHOW_LOADER_FOR_NEW_SERVICEREQUEST: 'SHOW_LOADER_FOR_NEW_SERVICEREQUEST',
  POST_SERVICEREQUEST_SUCCESS: 'POST_SERVICEREQUEST_SUCCESS',
  POST_SERVICEREQUEST_FAILURE: 'POST_SERVICEREQUEST_FAILURE',
  SHOW_LOADER_FOR_OLD_SERVICEREQUEST: 'SHOW_LOADER_FOR_OLD_SERVICEREQUEST',
  RESET_FORM_RESET_BOOLEAN: 'RESET_FORM_RESET_BOOLEAN',
  GET_GETTEAMSERVICEREQUESTS_SUCCESS: 'GET_GETTEAMSERVICEREQUESTS_SUCCESS',
  GET_GETTEAMSERVICEREQUESTS_FAILURE: 'GET_GETTEAMSERVICEREQUESTS_FAILURE',
  POST_TEAMSERVICEREQUEST_STATUS_SUCCESS: 'POST_TEAMSERVICEREQUEST_STATUS_SUCCESS',
  POST_TEAMSERVICEREQUEST_STATUS_FAILURE: 'POST_TEAMSERVICEREQUEST_STATUS_FAILURE',
  SHOW_LOADER_FOR_POSTTEAM_ACTION: 'SHOW_LOADER_FOR_POSTTEAM_ACTION',
  SHOW_LOADER_FOR_TEAM_SERVICEREQUEST: 'SHOW_LOADER_FOR_TEAM_SERVICEREQUEST',
  SET_SR_RECORDS_ITGLOBAL: 'SET_SR_RECORDS_ITGLOBAL',

  CHANGE_TAB: 'CHANGE_TAB',
  CHANGE_TAB_LOADER: 'CHANGE_TAB_LOADER',
  CHANGE_TAB_FAILURE: 'CHANGE_TAB_FAILURE',

  GET_CASE_INFO_SUCCESS: 'GET_CASE_INFO_SUCCESS',
  GET_CASE_INFO_FAILURE: 'GET_CASE_INFO_FAILURE',
  GET_CASE_INFO_LOADER: 'GET_CASE_INFO_LOADER',

  FOLLOWUP_SUCCESS: 'FOLLOWUP_SUCCESS',
  FOLLOWUP_FAILURE: 'FOLLOWUP_FAILURE',
};
