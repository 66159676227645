import { actionTypes } from '../actions/actionTypes/MDMActionTypes';

let initialState = {
  loaderForMDMGet: false,
  MDMCases: [],
  PageIndexCount: 0,
  openMDMLoader: false,
  MaintananceData: {},
  approveOrRejectCaseLoader: false,
  pyNote: '',
  pyMessageType: '',
};

export function MDM(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_MDM_SUCCESS:
      return {
        ...state,
        loaderForMDMGet: false,
        pyNote: '',
        MDMCases: action?.data?.MDMCaseList || [],
        PageIndexCount: action?.data?.PageIndexCount || 0,
      };

    case actionTypes.GET_MDM_FAILURE:
      return {
        ...state,
        loaderForMDMGet: false,
        PageIndexCount: 0,
        MDMCases: [],
      };
    case actionTypes.GET_MDM_LOADER:
      return {
        ...state,
        loaderForMDMGet: true,
      };

    case actionTypes.OPEN_MDM_SUCCESS:
      return {
        ...state,
        openMDMLoader: false,
        pyNote: action?.data?.pyNote,
        pyMessageType: action?.data?.pyMessageType,

        MaintananceData: action?.data?.MaintananceData || {},
      };

    case actionTypes.OPEN_MDM_FAILURE:
      return {
        ...state,
        openMDMLoader: false,
        MaintananceData: {},
      };
    case actionTypes.OPEN_MDM_LOADER:
      return {
        ...state,
        openMDMLoader: true,
      };

    case actionTypes.APPROVE_OR_REJECT_MDM_SUCCESS:
      return {
        ...state,
        MDMCases: action?.data?.MDMCaseList || [],
        PageIndexCount: action?.data?.PageIndexCount || 0,
        approveOrRejectCaseLoader: false,
      };
    case actionTypes.APPROVE_OR_REJECT_MDM_FAILURE:
      return {
        ...state,
        approveOrRejectCaseLoader: false,
      };
    case actionTypes.APPROVE_OR_REJECT_LOADER:
      return {
        ...state,
        approveOrRejectCaseLoader: true,
      };

    case actionTypes.SET_DATA_MDM:
      return {
        ...state,
        pyNote: '',
        pyMessageType: '',
      };

    default:
      return state;
  }
}
