import { actionTypes } from '../actions/actionTypes/mboActionType';

let initialState = {
  goals: [],
  employees: [],
  userGoals: [],
  organizationLoader: false,
  employeeLoader: false,
  showSubmit: false,
  IsEditable: false,
  comments: [],
  showCommentsLoader: false,
  reviews: [],
  updateOneonOneLoader: false,
  pyUILabel: 'Submit',
  sendBackComments: '',
  HRAssignedCases: [],
  HRFeedBack: [],
  MBOStage: '',
  EmployeeObjectiveScore: '',
  completeHRAssessment: {},
  completeHRAssessmentLoader: false,
  ShowHRAssessment: false,
  OneOnOneSlots: [],
  getSlotsOneOnOneLoader: false,
  AllowNewOneOnOne: false,
  oneononeActionsLoader: false,
  IsEdit: false,
  saveMBOReviewLoader: false,
  openExcelFormatLoader: false,
};

/**
 * Redux reducers.
 * Used to update state in the store after actions are issued.
 */
export function mbo(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_DATA:
      return {
        ...state,
        goals: action.data,
      };
    case actionTypes.SET_DATA_REVIEWS:
      return {
        ...state,
        reviews: action.data,
      };
    case actionTypes.GET_GOALS_SUCCESS:
      return {
        ...state,
        goals: action.goals.OrganizationGoals,
        HRFeedBack: action.goals.HRFeedBack,
        caseId: action?.goals?.pyID || '',
        MBOStage: action?.goals?.MBOStage || '',
        EmployeeObjectiveScore: action?.goals?.EmployeeObjectiveScore || '',
        organizationLoader: false,
        showSubmit: action?.goals?.showSubmit === 'true' ? true : false,
        IsEditable: action?.goals?.IsEditable === 'true' ? true : false,
        IsManager: action?.goals?.IsManager === 'true' ? true : false,
        // MBOStage: action?.goals?.MBOStage,
        pyStatusWork: action.goals.pyStatusWork,
        reviews: action.goals?.Reviews || [],
        pyUILabel: action.goals.pyUILabel,
        sendBackComments: action.goals.sendBackComments,
        IsEdit: false,
        saveMBOReviewLoader: false,
        OneOnOneSlots: [],
      };

    case actionTypes.GET_GOALS_FAILURE:
      return {
        ...state,
        goals: [],
        HRFeedBack: [],
        organizationLoader: false,
        showSubmit: action?.goals?.showSubmit === 'true' ? true : false,
        IsEditable: true,
        IsManager: false,
        reviews: [],
        sendBackComments: '',
        saveMBOReviewLoader: false,
        OneOnOneSlots: [],
      };

    case actionTypes.POST_GOALS_SUCCESS:
      return {
        ...state,
        goals: action.goals.OrganizationGoals,
        caseId: action?.goals?.pyID || '',
        organizationLoader: false,
        showSubmit: action?.goals?.showSubmit === 'true' ? true : false,
        status: action?.goals?.pyStatusWork,
        IsEditable: action?.goals?.IsEditable === 'true' ? true : false,
        IsManager: action?.goals?.IsManager === 'true' ? true : false,
        MBOStage: action.goals.MBOStage,
        pyStatusWork: action.goals.pyStatusWork,
        reviews: action.goals?.Reviews || [],
        pyUILabel: action.goals.pyUILabel,
        sendBackComments: action?.goals?.sendBackComments,
      };

    case actionTypes.POST_GOALS_FAILURE:
      return {
        ...state,
        caseId: action?.goals?.pyID || '',
        organizationLoader: false,
        showSubmit: action?.goals?.showSubmit === 'true' ? true : false,
        IsEditable: false,
        IsManager: false,
        reviews: [],
        sendBackComments: '',
      };

    case actionTypes.GET_TEAM_GOALS_SUCCESS:
      return {
        ...state,
        employees: action?.data?.ReporteesMBOs || [],
        userGoals: [],
        ShowHRAssessment: action?.data?.ShowHRAssessment === 'true' ? true : false || '',
        goals: [],
        organizationLoader: false,
        employeeLoader: false,
        HRAssignedCases: action?.data?.HRAssignedCases || [],
      };
    case actionTypes.GET_TEAM_GOALS_FAILURE:
      return {
        ...state,
        employees: [],
        userGoals: [],
        goals: [],
        organizationLoader: false,
        employeeLoader: false,
        status: action?.goals?.pyStatusWork,
        HRAssignedCases: [],
      };
    case actionTypes.SHOW_LOADER_FOR_ORG_LOADER:
      return {
        ...state,
        organizationLoader: true,
        goals: [],
      };

    case actionTypes.SHOW_LOADER_FOR_EMP_LOADER:
      return {
        ...state,
        employeeLoader: true,
        goals: [],
      };

    case actionTypes.GET_MBO_INTERACTIONS_SUCCESS:
      return {
        ...state,
        comments: action.intercations.InteractionComments,
        AllowNewOneOnOne: action.intercations.AllowNewOneOnOne === 'true' ? true : false,
        IsEdit: action.intercations.IsEdit === 'true' ? true : false,

        showCommentsLoader: false,
        showAddCommentsLoader: false,
        goals: action.intercations.OrganizationGoals
          ? action.intercations.OrganizationGoals
          : state.goals,
        showSubmit: action?.intercations?.showSubmit
          ? action?.intercations?.showSubmit === 'true'
            ? true
            : false
          : state.showSubmit,
        IsEditable: action?.intercations?.IsEditable
          ? action?.intercations?.IsEditable === 'true'
            ? true
            : false
          : state.IsEditable,
        IsManager: action?.intercations?.IsManager
          ? action?.intercations?.IsManager === 'true'
            ? true
            : false
          : state.IsManager,
        MBOStage: action?.intercations?.MBOStage || state.MBOStage,
        pyStatusWork: action?.intercations?.pyStatusWork || state.pyStatusWork,
        reviews: action.intercations?.Reviews || state.reviews,
        updateOneonOneLoader: false,
        oneononeActionsLoader: false,
        pyUILabel: action?.goals?.pyUILabel || state.pyUILabel,
      };

    case actionTypes.GET_MBO_INTERACTIONS_FAILURE:
      return {
        ...state,
        comments: [],
        showCommentsLoader: false,
        showAddCommentsLoader: false,
        updateOneonOneLoader: false,
        oneononeActionsLoader: false,
      };

    case actionTypes.ONEONONE_ACTIONS_LOADER:
      return {
        ...state,
        oneononeActionsLoader: true,
      };

    case actionTypes.SHOW_LOADER_FOR_COMMENTS:
      return {
        ...state,
        showCommentsLoader: true,
      };

    case actionTypes.LOADER_FOR_ADD_COMMENTS:
      return {
        ...state,
        showAddCommentsLoader: true,
      };

    case actionTypes.LOADER_FOR_UPDATE_ONEONONE:
      return {
        ...state,
        updateOneonOneLoader: true,
      };
    case actionTypes.CLEAR_DATA_MBO:
      return {
        ...state,
        HRFeedBack: [],
        goals: [],
      };
    case actionTypes.COMPLETE_HR_ASSESSSMENT_SUCCESS:
      return {
        ...state,
        HRAssignedCases: action?.data?.HRAssignedCases || [],
        completeHRAssessmentLoader: false,
        completeHRAssessment: action?.data || {},
      };
    case actionTypes.COMPLETE_HR_ASSESSSMENT_FAILURE:
      return {
        ...state,
        HRAssignedCases: [],
        completeHRAssessmentLoader: false,
        completeHRAssessment: {},
      };
    case actionTypes.COMPLETE_HR_ASSESSSMENT_LOADER:
      return {
        ...state,
        completeHRAssessmentLoader: true,
      };
    case actionTypes.GET_SLOTS_ONEONONE_SUCCESS:
      return {
        ...state,
        OneOnOneSlots: action?.intercations?.data?.DisplaySlots || [],
        getSlotsOneOnOneLoader: false,
      };
    case actionTypes.GET_SLOTS_ONEONONE_FAILURE:
      return {
        ...state,
        OneOnOneSlots: [],
        getSlotsOneOnOneLoader: false,
      };
    case actionTypes.GET_SLOTS_ONEONONE_LOADER:
      return {
        ...state,
        getSlotsOneOnOneLoader: true,
      };

    case actionTypes.SAVE_MBO_REVIEWS_LOADER:
      return {
        ...state,
        saveMBOReviewLoader: true,
      };

    case actionTypes.OPEN_EMPTY_EXCEL_FORMATE_SUCCESS:
      return {
        ...state,
        openExcelFormatLoader: false,
      };
    case actionTypes.OPEN_EMPTY_EXCEL_FORMATE_FAILURE:
      return {
        ...state,
        openExcelFormatLoader: false,
      };
    case actionTypes.OPEN_EMPTY_EXCEL_FORMATE_LOADER:
      return {
        ...state,
        openExcelFormatLoader: true,
      };

    default:
      return state;
  }
}
