
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
// import logger from 'redux-logger'


// TODO: use compose. Ref.: https://github.com/react-boilerplate/react-boilerplate/blob/d19099afeff64ecfb09133c06c1cb18c0d40887e/app/configureStore.js#L14
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export function configureStore() {
 const enhancers = [
   applyMiddleware(thunk),
  //  applyMiddleware(logger)
 ];

 const store = createStore(rootReducer, undefined, composeEnhancers(...enhancers));
 return store;
}
