import { actionTypes } from '../actions/actionTypes/serviceRequestActionTypes';

let initialState = {
  showNewServiceRequestLoader: false,
  showLoaderForOldRecords: true,
  resetFormData: false,
  showTeamServiceRequestLoader: false,
  showLoaderForAction: false,
  pagination: 0,
  AdminCaseInfo: [],
  FinanceCaseInfo: [],
  HRCaseInfo: [],
  ITCaseInfo: [],
  LogisticsCaseInfo: [],
  BenefitsCaseInfo: [],
  RMCaseInfo: [],
  changeTabLoader: false,
  TabInfo: [],
  getCaseInfoLoader: false,
  caseAuditInfo: {},
};

/**
 * Redux reducers.
 * Used to update state in the store after actions are issued.
 */
export function serviceRequests(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_OLDSERVICEREQUESTS_SUCCESS:
      return {
        ...state,
        oldServiceRequests: action?.cases?.data1?.CaseInfo || [],
        SRDepartments: action?.cases?.data1?.SRDepartments,
        impactList: action?.cases?.data1?.SRImpacts,
        userDetails: action?.cases?.data1?.UserDetails[0] || [],
        showLoaderForOldRecords: false,
        pagination: parseInt(action?.cases?.data1?.PageIndexCount) || 0,
        showSubmittedData: action?.cases?.data2?.CaseInfo[0],
      };

    case actionTypes.GET_OLDSERVICEREQUESTS_FAILURE:
      return {
        ...state,
        oldServiceRequests: [],
        SRDepartments: [],
        impactList: [],
        userDetails: [],
        showLoaderForOldRecords: false,
        pagination: 0,
      };

    // case actionTypes.GET_OLDSERVICEREQUESTS_FAILURE:
    //   return {
    //     ...state,
    //     showLoaderForOldRecords: false,
    //   };

    case actionTypes.SHOW_LOADER_FOR_POSTTEAM_ACTION:
      return {
        ...state,
        showLoaderForAction: true,
      };

    case actionTypes.SHOW_LOADER_FOR_OLD_SERVICEREQUEST:
      return {
        ...state,
        showLoaderForOldRecords: true,
      };

    case actionTypes.GET_GETUSERS_SUCCESS:
      return {
        ...state,
        getColleagueDetails: action.cases.UserSrchResults,
      };

    case actionTypes.RESET_FORM_RESET_BOOLEAN:
      return {
        ...state,
        resetFormData: false,
      };

    case actionTypes.SHOW_LOADER_FOR_NEW_SERVICEREQUEST:
      return {
        ...state,
        showNewServiceRequestLoader: true,
        showLoaderForOldRecords: true,
      };

    case actionTypes.POST_SERVICEREQUEST_SUCCESS:
      return {
        ...state,
        showNewServiceRequestLoader: false,
        oldServiceRequests: action.cases.CaseInfo || [],
        showLoaderForOldRecords: false,
        resetFormData: true,
        showSubmittedData: undefined,
        pagination: parseInt(action?.cases?.data1?.PageIndexCount) || 0,

      };

    case actionTypes.POST_SERVICEREQUEST_FAILURE:
      return {
        ...state,
        showNewServiceRequestLoader: false,
        showLoaderForOldRecords: false,
        resetFormData: false,
      };

    case actionTypes.SHOW_LOADER_FOR_TEAM_SERVICEREQUEST:
      return {
        ...state,
        showTeamServiceRequestLoader: true,
      };

    case actionTypes.GET_GETTEAMSERVICEREQUESTS_SUCCESS:
      return {
        ...state,
        showTeamServiceRequestLoader: false,
        getTeamRecords: action?.cases?.CaseInfo || [],
        pagination: parseInt(action?.cases?.PageIndexCount),
        AdminCaseInfo: action?.cases?.AdminCaseInfo || [],
        FinanceCaseInfo: action?.cases?.FinanceCaseInfo || [],
        HRCaseInfo: action?.cases?.HRCaseInfo || [],
        ITCaseInfo: action?.cases?.ITCaseInfo || [],
        LogisticsCaseInfo: action?.cases?.LogisticsCaseInfo || [],
        BenefitsCaseInfo: action?.cases?.BenefitsCaseInfo || [],
        RMCaseInfo: action?.cases?.RMCaseInfo || [],
        TabInfo: action?.cases?.TabInfo || [],
      };

    case actionTypes.GET_GETTEAMSERVICEREQUESTS_FAILURE:
      return {
        ...state,
        showTeamServiceRequestLoader: false,
        getTeamRecords: [],
      };

    case actionTypes.POST_TEAMSERVICEREQUEST_STATUS_SUCCESS:
      return {
        ...state,
        showTeamServiceRequestLoader: false,
        postSRActionData: action?.cases?.data1 || {},
        getTeamRecords: action?.cases?.data1?.CaseInfo || [],
        showLoaderForAction: false,
        pagination: parseInt(action?.cases?.data1?.PageIndexCount) || 0,
        AdminCaseInfo: action?.cases?.data1?.AdminCaseInfo || [],
        FinanceCaseInfo: action?.cases?.data1?.FinanceCaseInfo || [],
        HRCaseInfo: action?.cases?.data1?.HRCaseInfo || [],
        ITCaseInfo: action?.cases?.data1?.ITCaseInfo || [],
        LogisticsCaseInfo: action?.data1?.cases?.LogisticsCaseInfo || [],
        BenefitsCaseInfo: action?.cases?.data1?.BenefitsCaseInfo || [],
        RMCaseInfo: action?.cases?.data1?.RMCaseInfo || [],
      };

    case actionTypes.POST_TEAMSERVICEREQUEST_STATUS_FAILURE:
      return {
        ...state,
        showLoaderForAction: false,
      };
    case actionTypes.SET_SR_RECORDS_ITGLOBAL:
      return {
        ...state,
        getTeamRecords: action?.data?.getTeamRecords || [],
      };
    case actionTypes.CHANGE_TAB:
      return {
        ...state,
        changeTabLoader: false,
      };
    case actionTypes.CHANGE_TAB_FAILURE:
      return {
        ...state,
        changeTabLoader: false,
      };

    case actionTypes.CHANGE_TAB_LOADER:
      return {
        ...state,
        changeTabLoader: true,
      };

    case actionTypes.GET_CASE_INFO_SUCCESS:
      return {
        ...state,
        getCaseInfoLoader: false,
        caseAuditInfo: action.cases?.data1?.CaseInfo[0] || {},
      };
    case actionTypes.GET_CASE_INFO_FAILURE:
      return {
        ...state,
        getCaseInfoLoader: false,
        caseAuditInfo: {},
      };
    case actionTypes.GET_CASE_INFO_LOADER:
      return {
        ...state,
        getCaseInfoLoader: true,
      };

    default:
      return state;
  }
}
