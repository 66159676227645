import { makeStyles } from '@mui/styles';

const LoginStyles = makeStyles((theme) => {
  return {
    container: {
      width: '100%',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row-reverse',
      justifyContent: 'space-around',
    },
    leftContainer: {
      backgroundColor: '#003fbd !important',
      // width: '50%',
      color: '#fff',
      // textAlign: 'center',
      // height: '150%',
      width: '100%',
      left: '-5%',
      display: 'flex !important',
      alignItems: 'center !important',
      justifyContent: 'center !important',
      flexDirection: 'column !important',
      borderBottomRightRadius: '90%',
      // zIndex: '6',
      // content: "",
      position: 'absolute',

      [theme.breakpoints.down('sm')]: {
        // width: '50%',
        color: '#fff',
        textAlign: 'center',
        top: '-10%',
        height: '50%',
        width: '107%',
        paddingLeft: '15px',
        paddingRight: '25px',
      },
      [theme.breakpoints.down('md')]: {
        // width: '50%',
        color: '#fff',
        textAlign: 'center',
        top: '-10%',
        height: '50%',
        width: '107%',
        paddingLeft: '15px',
        paddingRight: '25px',
      },
    },
    inputs: {
      width: '90%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    rightContainer: {
      display: 'flex !important',
      alignItems: 'center !important',
      justifyContent: 'center!important',
      flexDirection: 'column !important',
      // position: 'absolute',
      boxShadow: theme.palette.customBoxShadows.boxShadow,
      // backgroundColor:'#f1faff',
      // left: '55%',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        left: '0%',
        boxShadow: 'none',
        top: '33%',
        margin: theme.spacing(2, 0),
      },
      [theme.breakpoints.down('md')]: {
        left: '0%',
        margin: theme.spacing(2, 0),
        boxShadow: 'none',
        top: '33%',
      },
    },

    companyLogo: {
      width: '125px',
      marginRight: '16px',
    },
  };
});

export default LoginStyles;
