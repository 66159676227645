export const actionTypes = {
  GET_OLDEXPENSES_SUCCESS: 'GET_OLDEXPENSES_SUCCESS',
  GET_OLDEXPENSES_FAILURE: 'GET_OLDEXPENSES_FAILURE',
  SHOW_LOADER: 'SHOW_LOADER',
  CONVERTED_TOTAL_CURRENCY_SUCCESS: 'CONVERTED_TOTAL_CURRENCY_SUCCESS',
  CONVERTED_TOTAL_CURRENCY_FAILURE: 'CONVERTED_TOTAL_CURRENCY_FAILURE',
  POST_INDIVIDUALEXPENSE_SUCCESS: 'POST_INDIVIDUALEXPENSE_SUCCESS',
  POST_INDIVIDUALEXPENSE_FAILURE: 'POST_INDIVIDUALEXPENSE_FAILURE',
  SHOW_LOADER_FOR_ADDING_NEW_EXPENSE: 'SHOW_LOADER_FOR_ADDING_NEW_EXPENSE',
  GET_TEAM_EXPENESES_SUCCESS: 'GET_TEAM_EXPENESES_SUCCESS',
  GET_TEAM_EXPENESES_FAILURE: 'GET_TEAM_EXPENESES_FAILURE',
  POST_TEAM_STATUS: 'POST_TEAM_STATUS',
  POST_TEAM_FAILURE: 'POST_TEAM_FAILURE',
  SHOW_LOADER_FOR_GETTING_OLD_EXPENSE: 'SHOW_LOADER_FOR_GETTING_OLD_EXPENSE',
  SHOW_LOADER_FOR_GETTING_TEAM_EXPENSE: 'SHOW_LOADER_FOR_GETTING_TEAM_EXPENSE',
  POST_TEAM_STATUS_LOADER: 'POST_TEAM_STATUS_LOADER',
  GET_RECIEPTDATA_SUCCESS: 'GET_RECIEPTDATA_SUCCESS',
  GET_RECIEPTDATA_FAILURE: 'GET_RECIEPTDATA_FAILURE',
  DISABLE_WHILE_RECIEPT_CALL: 'DISABLE_WHILE_RECIEPT_CALL',
  UPDATE_OR_DELETE_LINEITEMS: 'UPDATE_OR_DELETE_LINEITEMS',
  CLEAR_RECIPT_DATA: 'CLEAR_RECIPT_DATA',
  SET_ACTION_TYPE: 'SET_ACTION_TYPE',
  CLEAR_RESUBMIT_DATA: 'CLEAR_RESUBMIT_DATA',
  VIEW_RECIEPT_SUCCESS:'VIEW_RECIEPT_SUCCESS',
  OPEN_CASE_FOR_RESUBMIT_SUCCESS: 'OPEN_CASE_FOR_RESUBMIT_SUCCESS',
  OPEN_CASE_FOR_RESUBMIT_FAILURE: 'OPEN_CASE_FOR_RESUBMIT_FAILURE',
  OPEN_CASE_FOR_RESUBMIT_LOADER: 'OPEN_CASE_FOR_RESUBMIT_LOADER',

  UPDATE_RECIPT_DATA: 'UPDATE_RECIPT_DATA',

  EXPENSE_WITHDRAW_SUCCESS: 'EXPENSE_WITHDRAW_SUCCESS',
  EXPENSE_WITHDRAW_FAILURE: 'EXPENSE_WITHDRAW_FAILURE',

  SET_EXPENSE_TYPE: 'SET_EXPENSE_TYPE',
  SET_IS_YODLEE_OPEN: 'SET_IS_YODLEE_OPEN',

  GENERATE_TOKEN_SUCCESS: 'GENERATE_TOKEN_SUCCESS',
  GENERATE_TOKEN_FAILURE: 'GENERATE_TOKEN_FAILURE',
  GENERATE_TOKEN_LOADER: 'GENERATE_TOKEN_LOADER',

  LINK_ACCOUNT_SUCCESS: 'LINK_ACCOUNT_SUCCESS',
  LINK_ACCOUNT_FAILURE: 'LINK_ACCOUNT_FAILURE',
  LINK_ACCOUNT_LOADER: 'LINK_ACCOUNT_LOADER',

  GET_TRANSACTIONS_SUCCESS: 'GET_TRANSACTIONS_SUCCESS',
  GET_TRANSACTIONS_FAILURE: 'GET_TRANSACTIONS_FAILURE',
  GET_TRANSACTIONS_LOADER: 'GET_TRANSACTIONS_LOADER',

  GET_ACCOUNTS_SUCCESS: 'GET_ACCOUNTS_SUCCESS',
  GET_ACCOUNTS_FAILURE: 'GET_ACCOUNTS_FAILURE',
  GET_ACCOUNTS_LOADER: 'GET_ACCOUNTS_LOADER',

  SET_SELECTED_TRANSACTIONS: 'SET_SELECTED_TRANSACTIONS',

  MAP_EXPENSE_LINEITEMS: 'MAP_EXPENSE_LINEITEMS',

  GET_LINE_ITEMS: 'GET_LINE_ITEMS',

  SET_ACCOUNT_DETAILS: 'SET_ACCOUNT_DETAILS',

  REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS',
  REGISTER_USER_FAILURE: 'REGISTER_USER_FAILURE',
  REGISTER_USER_LOADER: 'REGISTER_USER_LOADER',

  DOWNLOAD_RECIEPT_SUCCESS: 'DOWNLOAD_RECIEPT_SUCCESS',
  DOWNLOAD_RECIEPT_FAILURE: 'DOWNLOAD_RECIEPT_FAILURE',
  DOWNLOAD_RECIEPT_LOADER: 'DOWNLOAD_RECIEPT_LOADER',

  SET_EXPENSE_IMAGE_DATA: 'SET_EXPENSE_IMAGE_DATA',

  GET_EXPENSE_AUDIT_SUCCESS: 'GET_EXPENSE_AUDIT_SUCCESS',
  GET_EXPENSE_AUDIT_FAILURE: 'GET_EXPENSE_AUDIT_FAILURE',
  GET_EXPENSE_AUDIT_LOADER: 'GET_EXPENSE_AUDIT_LOADER',

  UPDATE_RESUBMISSION_NOTES: 'UPDATE_RESUBMISSION_NOTES',

  CLEAR_RESUBMISSION_NOTES: 'CLEAR_RESUBMISSION_NOTES',

  PAYMENT_RETRY_SUCCESS: 'PAYMENT_RETRY_SUCCESS',
  PAYMENT_RETRY_FAILURE: 'PAYMENT_RETRY_FAILURE',
  PAYMENT_RETRY_LOADER: 'PAYMENT_RETRY_LOADER',


  UPDATE_YODLEE_TRANSACTION_ATTACHMENT:'UPDATE_YODLEE_TRANSACTION_ATTACHMENT',
};
