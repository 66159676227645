import { actionTypes } from "../actions/actionTypes/oneononeActionType";

let initialState = {
  AssignedToMe: [],
  CreatedByMe: [],
  showGetOneonones: false,
  showLoaderForNewOneonone: false,
  resetForm: false,
  showLoaderForComment:false,

};

/** 
 * Redux reducers.
 * Used to update state in the store after actions are issued.
 */
export function oneonone(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ONEONONE_SUCCESS:
      return {
        ...state,
        AssignedToMe: action.oneonone.AssignedToMe,
        CreatedByMe: action.oneonone.CreatedByMe,
        showGetOneonones: false,
        showOneonOnepage:action.oneonone.allowOneOnOne === "true" ? true:false
      };

    case actionTypes.GET_ONEONONE_FAILURE:
      return {
        ...state,
        AssignedToMe: [],
        CreatedByMe: [],
        showGetOneonones: false
      }

    case actionTypes.GET_ONEONONE_LOADER:
      return {
        ...state,
        showGetOneonones: true
      }

    case actionTypes.POST_ONEONONE_SUCCESS:
      return {
        ...state,
        AssignedToMe:action.oneonone.AssignedToMe,
        CreatedByMe:action.oneonone.CreatedByMe,
        showLoaderForNewOneonone: false,
        resetForm:true,
        showLoaderForComment:false
      };

    case actionTypes.POST_ONEONONE_FAILURE:
      return {
        ...state,
        // AssignedToMe:action.oneonone.AssignedToMe,
        // CreatedByMe:action.oneonone.CreatedByMe,
        showLoaderForNewOneonone: false,
        resetForm:false,
        showLoaderForComment:false
      };


    case actionTypes.POST_NEW_ONEONONE_LOADER:
      return {
        ...state,
        showLoaderForNewOneonone: true,
        resetForm:false
      }

    case actionTypes.POST_NEW_COMMENT_LOADER:
      return {
        ...state,
        showLoaderForComment: true,
      }

  


    default:
      return state;
  }
}



